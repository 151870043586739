<template>
  <div v-if="isVisible" class="form-container bg-gray-50">
    <div
      class="p-4 pt-8 container max-w-screen-lg mx-auto bg-white shadow mb-6"
    >
      <!-- Top Header -->
      <button class="trigger-hide" @click="triggerHideForm()">
        <fa-icon icon="times"></fa-icon>
      </button>
      <div class="form__header">
        <div class="container max-w-screen-lg mx-auto grid grid-cols-2 gap-2">
          <!-- form links -->
          <div class="col-span-2 p-2 text-sm text-right text-green-700">
            <button
              class="p-2 uppercase tracking-wider"
              @click="SHOW_FORM('core')"
            >
              inti
            </button>
            <button
              class="p-2 uppercase tracking-wider"
              @click="SHOW_FORM('address')"
            >
              Alamat
            </button>
            <button
              class="p-2 uppercase tracking-wider"
              @click="SHOW_FORM('father')"
            >
              Ayah
            </button>
            <button
              class="p-2 uppercase tracking-wider"
              @click="SHOW_FORM('mother')"
            >
              Ibu
            </button>
            <button
              class="p-2 uppercase tracking-wider"
              @click="SHOW_FORM('guardian')"
            >
              Wali
            </button>
          </div>
          <h1
            class="p-2 font-bold uppercase text-left tracking-wider md:text-xl"
          >
            {{ title }}
          </h1>
          <div class="text-right pr-3">
            <!-- trigger add new period -->
            <button class="button --primary" @click="triggerUpdate()">
              <fa-icon icon="save"></fa-icon>
              <span class="ml-2">simpan</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Form -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    triggerUpdate() {
      this.$emit("update-triggered");
    },
    triggerHideForm() {
      this.HIDE_FORM();
      // this.$emit("hide");
    },

    ...mapMutations("student", ["HIDE_FORM", "SHOW_FORM"]),
  },
  name: "StudentForm",
};
</script>


<style lang="scss" scoped>
.form-container {
  @apply fixed top-0 left-0 p-8 pt-32  z-30 w-full overflow-y-auto max-h-screen;
}

.form__header {
  @apply mb-10 gap-2 shadow;
  @apply fixed w-full top-0 left-0 pb-4 pt-1 z-10 bg-white;
}

.trigger-hide {
  @apply fixed left-3 -mt-2 top-0 w-12 h-12 z-20;
  @apply shadow-lg text-white text-xl;
  @apply rounded-b-full bg-gray-800 bg-opacity-80;
}

.form-label {
  @apply uppercase tracking-wider mb-1 mt-4 block;
}

input[type="text"] {
  @apply w-full;
}
</style>